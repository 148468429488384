import { useCallback } from 'react';
import {
  usePrecheckFooterActions,
  usePrecheckFooterExpanded,
  usePrecheckFooterExpandedLocked,
  usePrecheckFooterVisible,
} from './usePrecheckFooterStore';

const usePrecheckFooter = () => {
  const visible = usePrecheckFooterVisible();
  const expanded = usePrecheckFooterExpanded();
  const expandedLocked = usePrecheckFooterExpandedLocked();
  const { setVisible, setExpanded, setExpandedLocked } = usePrecheckFooterActions();

  const show = useCallback(() => setVisible(true), [setVisible]);

  const hide = useCallback(() => setVisible(false), [setVisible]);

  const expand = useCallback(
    ({ preventCollapse = false } = {}) => {
      if (preventCollapse) {
        setExpandedLocked(true);
      }

      setExpanded(true);
    },
    [setExpanded, setExpandedLocked],
  );

  const collapse = useCallback(() => {
    if (expandedLocked) {
      return;
    }

    setExpanded(false);
  }, [setExpanded, expandedLocked]);

  return {
    visible,
    expanded,
    show,
    hide,
    expand,
    collapse,
  };
};

export { usePrecheckFooter };
