import { useTranslation } from 'next-i18next';
import { Container, Stack, Button, Text, Grid, GridItem } from '@carvertical/ui';
import { useEffect } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { GotCreditsQuestion } from 'components/common/GotCreditsQuestion';
import { BuyReportButton } from 'components/common/BuyReportButton';
import { PrecheckPricingBlock } from 'modules/precheck/components/PrecheckPricingBlock';
import { B2BMarketSwitch } from 'modules/precheck/components/B2BMarketSwitch';
import { isExistingMarket } from 'utils/market';
import { CreditsValidityNotice } from 'components/common/CreditsValidityNotice';
import { PaymentLogos } from 'components/common/PaymentLogos';
import type { MarketId } from 'modules/market';
import { RefundPolicyModal } from 'modules/precheck/components/RefundPolicyModal';
import { usePricingBlocks } from './hooks';
import styles from './PricingSection.module.scss';

type PricingSectionProps = {
  className?: string;
  b2bSwitchHidden?: boolean;
  loginLinkHidden?: boolean;
};

type GridItemStartingPosition = 1 | 3 | 5 | 7 | 9;

const VISIBLE_VAT_MARKETS: MarketId[] = ['germany'];

const GRID_COLUMNS = 12;
const getPackageColumns = (packageCount: number) => (packageCount % 2 ? 4 : 6);

const PricingSection = ({ className, b2bSwitchHidden, loginLinkHidden }: PricingSectionProps) => {
  const { t } = useTranslation(['checkout', 'precheck', 'common']);
  const { market } = useRouteData();
  const {
    businessPackagesShown,
    maxBusinessDiscount,
    pricing,
    selectedPackageId,
    toggleBusinessPackages,
    selectPackage,
    shownPackages,
  } = usePricingBlocks();
  const { length: packageCount } = shownPackages;
  const packageColumns = getPackageColumns(packageCount);

  const getStartingPosition = (index: number) => {
    const emptyCellsCount = GRID_COLUMNS - packageCount * packageColumns;
    const halfEmptyCellsCount = Math.floor(emptyCellsCount / 2);
    const baseStartingPosition = index * packageColumns;
    const startingPosition = baseStartingPosition + 1 + halfEmptyCellsCount;

    return startingPosition as GridItemStartingPosition;
  };

  const vatShown = isExistingMarket({
    market,
    markets: VISIBLE_VAT_MARKETS,
  });

  const badgeText = maxBusinessDiscount ? `-${maxBusinessDiscount}%` : undefined;

  useEffect(() => {
    toggleBusinessPackages(false);
  }, [toggleBusinessPackages]);

  return (
    <div className={className}>
      <Container>
        <Stack gap={4} crossAxisAlign="stretch">
          {!b2bSwitchHidden && (
            <B2BMarketSwitch onChange={toggleBusinessPackages} discount={badgeText} />
          )}

          <Grid as="ul" rowGap={2} columnGap={2} className={styles.wrapper}>
            {shownPackages.map(({ id }, index) => (
              <GridItem
                as="li"
                className={styles.item}
                key={id}
                span={{ mobileUp: 12, desktopUp: packageColumns }}
                start={{ mobileUp: 1, desktopUp: getStartingPosition(index) }}
              >
                <Stack className={styles.pricingBlock} crossAxisAlign="stretch" gap={0}>
                  <PrecheckPricingBlock
                    businessPricingShown={businessPackagesShown}
                    id={id}
                    pricing={pricing[id]}
                    selected={selectedPackageId === id}
                    onChange={() => selectPackage(id)}
                  />
                </Stack>
              </GridItem>
            ))}
          </Grid>
        </Stack>

        <Stack gap={2} crossAxisAlign="center">
          <BuyReportButton
            as={Button}
            preselectedPackageId={selectedPackageId}
            className={styles.button}
            type="submit"
            variant="yellow"
            // @ts-expect-error TS(2339)
            disabled={pricing.fetching}
            size="l"
            fullWidth
          >
            {t('common:general.getReportAction')}
          </BuyReportButton>

          {!market.vat.alreadyIncluded && (
            <Text variant="xs+">{t('common:general.vatAppliesLabel')}</Text>
          )}

          {vatShown && <Text variant="xs+">{t('common:general.vatIncludedLabel')}</Text>}

          {!loginLinkHidden && <GotCreditsQuestion />}

          <PaymentLogos />

          <CreditsValidityNotice />
        </Stack>

        <RefundPolicyModal />
      </Container>
    </div>
  );
};

export { PricingSection };
