import { Badge, SkeletonLoader, Stack, Text, ToggleSwitch } from '@carvertical/ui';
import { useTranslation } from 'react-i18next';
import { NS_PRECHECK } from 'constants/i18n';
import CurlyArrow from './assets/curly-arrow.svg';

type B2BMarketSwitchProps = {
  discount?: string;
  onChange: (value: boolean) => void;
};

const B2BMarketSwitch = ({ discount, onChange }: B2BMarketSwitchProps) => {
  const { t } = useTranslation([NS_PRECHECK]);

  if (!discount) {
    return (
      <div className="relative mx-auto inline-flex flex-col">
        <SkeletonLoader radius="s" width={240} height={30} />
      </div>
    );
  }

  return (
    <div className="relative mx-auto inline-flex flex-col">
      <CurlyArrow className="ml-auto !hidden w-6 translate-x-[100%] sm:!block" />

      <ToggleSwitch
        size="l"
        onChange={(event) => onChange(event.target.checked)}
        label={
          // @ts-expect-error TS(2322)
          <Stack as={Text} type="horizontal" variant="s+" className="flex">
            {t('precheck:showBusinessPrices')}

            <Badge variant="blue" emphasized size="s">
              {discount}
            </Badge>
          </Stack>
        }
      />
    </div>
  );
};

export { B2BMarketSwitch };
export type { B2BMarketSwitchProps };
