import { create } from 'zustand';

type PrecheckFooterStore = {
  visible?: boolean;
  expanded?: boolean;
  expandedLocked?: boolean;
  actions: {
    setVisible: (visible: boolean) => void;
    setExpanded: (expanded: boolean) => void;
    setExpandedLocked: (expandedLocked: boolean) => void;
  };
};

const usePrecheckFooterStore = create<PrecheckFooterStore>((set) => ({
  visible: false,
  actions: {
    setVisible: (visible: boolean) => set({ visible }),
    setExpanded: (expanded: boolean) => set({ expanded }),
    setExpandedLocked: (expandedLocked: boolean) => set({ expandedLocked }),
  },
}));

const usePrecheckFooterVisible = () => usePrecheckFooterStore((store) => store.visible);
const usePrecheckFooterExpanded = () => usePrecheckFooterStore((store) => store.expanded);
const usePrecheckFooterExpandedLocked = () =>
  usePrecheckFooterStore((store) => store.expandedLocked);
const usePrecheckFooterActions = () => usePrecheckFooterStore((store) => store.actions);

export {
  usePrecheckFooterStore,
  usePrecheckFooterVisible,
  usePrecheckFooterExpanded,
  usePrecheckFooterExpandedLocked,
  usePrecheckFooterActions,
};
