import { Badge, Heading, Stack, Text } from '@carvertical/ui';
import type { ParseKeys } from 'i18next';
import { isEmpty } from 'lodash';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { CheckIconXS } from '@carvertical/icons/react';
import Loading from 'components/common/Loading';
import PrecheckContainer from 'containers/PrecheckContainer';
import withContainers from 'hoc/withContainers';
import Price from 'components/common/Price';
import type { OptionalPackageIdRecord, Package, Pricing } from 'types/pricing';
import { PricingExplanation, type PricingExplanationProps } from 'modules/shared';
import { usePreStickExperiment, usePricingLabel } from '../hooks';
import { usePrecheckFooter } from '../hooks/usePrecheckFooter';
import { RefundPolicyButton } from './RefundPolicyButton';
import styles from './PrecheckPricingBlock.module.scss';

type PrecheckPricingBlockProps = {
  id: Package['id'];
  containers: {
    report: PrecheckContainer;
  };
  pricing: Pricing;
  selected: boolean;
  onChange: () => void;
  businessPricingShown?: boolean;
  type?: 'precheck' | 'pricing';
};

type Banner = {
  translationKey: ParseKeys;
};

const BANNER: OptionalPackageIdRecord<Banner> = {
  double: {
    translationKey: 'pricing.mostPopularLabel',
  },
  quintuple: {
    translationKey: 'pricing.bestValueLabel',
  },
} as const;

const scrollToPricingBlock = (event: React.FocusEvent) => {
  event.preventDefault();
  const { scrollX: x, scrollY: y } = window;
  window.scrollTo(x, y);
};

// FIXME: Refactor & improve PrecheckPricingBlock component to reduce duplication & discrepancies.
// LAUNCH-2254 https://carvertical.atlassian.net/browse/LAUNCH-2254
const PrecheckPricingBlock = ({
  id,
  selected,
  pricing,
  onChange,
  containers: { report },
  businessPricingShown,
  type = 'pricing',
}: PrecheckPricingBlockProps) => {
  const { t } = useTranslation(['common', 'checkout', 'business']);
  const { enabled: preStickExperimentEnabled } = usePreStickExperiment();
  const { expand: expandPrecheckFooter } = usePrecheckFooter();

  const {
    $,
    discount,
    fetching,
    pricingBeforeDiscount,
    fullPrice,
    price,
    net,
    unitPrice,
    reportCount,
    savings,
    voucher,
  } = pricing;

  const standard = id === 'standard';
  const baseDiscount = !standard ? pricingBeforeDiscount?.baseDiscount || discount || 0 : 0;
  const totalDiscount = voucher && discount ? discount + baseDiscount : baseDiscount;
  const banner = BANNER[id];
  const pricingExplanationProps: Partial<PricingExplanationProps> = {
    ...(type === 'precheck' && {
      vehicleName: report.vehicleName,
      licensePlateNumber: report.licensePlate?.number,
    }),
    reportCount,
  };

  const { label } = usePricingLabel({ report, packageId: id });

  if (fetching || isEmpty(pricing)) {
    return (
      <div className={styles.root}>
        <div className={styles.cardWrapper}>
          <Loading block />
        </div>
      </div>
    );
  }

  const renderLabel = () => {
    const subLabel = (() => {
      return t('pricing.alsoCheckLabel');
    })();

    return (
      <Stack className={styles.labelWrapper} gap={1.5} type="horizontal" crossAxisAlign="center">
        <span className={styles.checkbox}>
          <CheckIconXS />
        </span>

        <Stack gap={0}>
          <Text variant="m+">{label}</Text>
          {!standard && (
            <Text variant="xs" textColor="darkSecondary">
              {subLabel}
            </Text>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <label
      htmlFor={`package-${id}`}
      className={cx(styles.root, !banner && styles.spaceTop, selected && styles.selected)}
    >
      <input
        id={`package-${id}`}
        type="radio"
        name="package"
        value={id}
        checked={selected}
        onFocus={scrollToPricingBlock}
        onChange={onChange}
        onClick={() => {
          if (preStickExperimentEnabled) {
            expandPrecheckFooter({ preventCollapse: true });
          }
        }}
        className={styles.input}
      />

      {banner && (
        <div className={styles.banner}>
          <Text variant="l+" textColor="light">
            {t(banner.translationKey)}
          </Text>
        </div>
      )}

      <Stack className={styles.cardWrapper} crossAxisAlign="stretch" gap={3}>
        {renderLabel()}

        <Stack className={styles.contentWrapper} crossAxisAlign="stretch" gap={3}>
          <Stack gap={2} crossAxisAlign="stretch">
            <Stack gap={1}>
              <Stack gap={0.5} type="horizontal" crossAxisAlign="flexEnd" wrap>
                <Heading as="span" variant="m">
                  <Price>{$(businessPricingShown ? net.unitPrice : unitPrice)}</Price>
                </Heading>

                <Text as="span" variant="l">
                  {t('pricing.unitPriceLabelShort')}
                </Text>
              </Stack>

              {!savings ? (
                <Text variant="m">{t('pricing.fullPrice')}</Text>
              ) : (
                <Text variant="m+">
                  {t('pricing.youPayLabel')}{' '}
                  <Price placement="bottom">{$(businessPricingShown ? net.price : price)}</Price>
                  <Text as="span" inline className={styles.fullPrice} variant="m">
                    {$(businessPricingShown ? net.fullPrice : fullPrice)}
                  </Text>
                </Text>
              )}
            </Stack>

            <Stack>
              {voucher ? (
                <Stack gap={2}>
                  <Badge size="s" emphasized icon="check">
                    {t('pricing.extraDiscountLabel')}
                  </Badge>

                  <Badge size="m" variant="red" emphasized>
                    <Text variant="s+" textColor="light">
                      {t('pricing.totalSavingsLabel', { savings: totalDiscount })}
                    </Text>
                  </Badge>
                </Stack>
              ) : (
                <Badge size="m" variant={totalDiscount ? 'red' : 'grey'} emphasized>
                  -{totalDiscount}%
                </Badge>
              )}
            </Stack>
          </Stack>

          <Stack gap={0} crossAxisAlign="stretch">
            <div className={styles.divider} style={{ '--marginTop': 0, '--marginBottom': 2 }} />

            <PricingExplanation {...pricingExplanationProps} />
          </Stack>

          <div className="mt-auto flex flex-col items-start">
            <div className={styles.divider} style={{ '--marginTop': 0, '--marginBottom': 2 }} />

            <RefundPolicyButton size="s" />
          </div>
        </Stack>
      </Stack>
    </label>
  );
};

const WrappedPrecheckPricingBlock = withContainers({
  report: PrecheckContainer,
})(PrecheckPricingBlock);

export { WrappedPrecheckPricingBlock as PrecheckPricingBlock };
