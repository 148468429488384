// https://carvertical.atlassian.net/browse/LAUNCH-2411
// LAUNCH-2411: test is stopped until implementation or removal
const usePreStickExperiment = () => {
  return {
    enabled: false,
    v1Enabled: false,
    v2Enabled: false,
  };
};

export { usePreStickExperiment };
