import type { ParseKeys } from 'i18next';
import { useTranslation } from 'next-i18next';
import type PrecheckContainer from 'containers/PrecheckContainer';
import { PrecheckState } from 'services/precheckState';
import { usePricingBlocks } from 'components/common/sections/PricingSection/hooks';
import type { Package } from 'types/pricing';

type PricingLabelProps = {
  packageId?: Package['id'];
  report: PrecheckContainer;
};

const usePricingLabel = ({ packageId, report }: PricingLabelProps) => {
  const { t } = useTranslation(['common', 'business']);

  const { businessPackagesShown, pricing } = usePricingBlocks();
  const precheckState = PrecheckState.get();

  const getPricingLabel = () => {
    if (!packageId) {
      return null;
    }

    let labelKey = (
      businessPackagesShown ? 'business:pricing.reportsLabel' : 'pricing.carsLabel'
    ) as ParseKeys;

    const motorcycle =
      report.bodyType?.value === 'motorcycle' ||
      precheckState?.spec?.bodyType?.value === 'motorcycle';

    if (motorcycle && !businessPackagesShown) {
      labelKey = 'pricing.bodyTypeReportsLabel.moto.check';
    }

    return t(labelKey, { count: pricing[packageId]?.reportCount });
  };

  return { label: getPricingLabel() };
};

export { usePricingLabel };
